/* @flow */

import * as React from 'react';

import {NutAnalyticsClient} from 'nutshell-core/nut-analytics-client';
import {Header, Subheader} from '../../typography';
import {BulletedList} from '../../bulleted-list/bulleted-list';
import {Stack} from '../../layout';
import {colors} from '../../colors';
import {BankSafe} from '../../illustrations/bank-safe';
import {UpgradeArrowIcon} from '../../icon/svg/upgrade-arrow-icon';
import type {FeatureName} from './features-list';
import {featureMessages} from './features-list';
import {FeatureGateButton} from './feature-gate-button';

import './feature-gate.css';

type DetailTextBullet = React.Node | string;

type Props = {
    header: string,
    subHeader: string,
    bullets: DetailTextBullet[],
    isAdmin: boolean,
    feature: FeatureName,
    analyticsClient?: NutAnalyticsClient,
    shouldShowButton?: boolean,
    icon?: any,
    cueCard?: React.Node,
    needLicenses?: boolean,
};

export function FeatureGatePage(props: Props) {
    const {isAdmin, feature, analyticsClient} = props;
    const closingMessage = featureMessages[feature];
    // for sms gates, we don't want to direct user to billing page if the
    // company already has licenses, regardless of if they have access to
    // billing.
    const shouldShowButton =
        typeof props.shouldShowButton === 'boolean' ? props.shouldShowButton : isAdmin;

    React.useEffect(() => {
        if (analyticsClient && feature) {
            analyticsClient.track(analyticsClient.EVENTS.TRACK_FEATURE_GATE_VIEWED, {
                feature,
                isAdmin,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className='full-height full-width flex flex-dir-row gap-48 pad-32 border-box background-white'>
            <div styleName='left-column'>
                <Stack spacing={4}>
                    <Header>
                        <span className='flex align-center gap-8 mb-4'>
                            <UpgradeArrowIcon size={25} wrapWithDiv={false} color={colors.blue} />
                            {props.header}
                        </span>
                    </Header>
                    <Subheader>{props.subHeader}</Subheader>
                </Stack>
                <div className='flex gap-48 mt-32 mb-32 align-center'>
                    <div styleName='bullets-wrapper'>
                        <BulletedList bullets={props.bullets} size='big' />
                    </div>
                    <div styleName='icon-container'>{props.icon || <BankSafe />}</div>
                </div>
                <div className='text-align-center font-size-normal'>{closingMessage}</div>
                {shouldShowButton && (
                    <div className='text-align-center pad-32'>
                        <FeatureGateButton variant={props.needLicenses ? 'licenses' : 'upgrade'} />
                    </div>
                )}
            </div>
            {props.cueCard ? <div styleName='right-column'>{props.cueCard}</div> : null}
        </div>
    );
}
