/* @flow */

import * as React from 'react';

import type {GetQuotes_quotes as Quote, QuoteDisplayStatus} from 'nutshell-graphql-types';

import {Stack} from '../../layout';
import {
    DownloadIcon,
    NavIconWebsiteIcon,
    HtmlLinkIcon,
    CopyIcon,
    PaperPlane,
    MinusCircleIcon,
} from '../../icon';
import {ActionMenu} from '../../action-menu';
import {TwoLine} from '../../two-line';
import {StatusBadge} from '../../status-badge';

import {getStatusLevel, getDisplayStatus} from './helpers';

import './quote-row.css';

type Props = {|
    quote: Quote,
    onOpenEditModal: (quoteId: string) => void,
    handleDownload: (slug: string) => void,
    handleUpdateStatus: (id: string, status: QuoteDisplayStatus) => void,
    handleDuplicateQuote: (id: string) => void,
|};

export function QuoteRow(props: Props) {
    const scriptRef = React.createRef<HTMLElement>();

    const options = [
        {
            label: 'View as customer',
            icon: <NavIconWebsiteIcon size={15} wrapWithDiv={false} />,
            handleSelect: () => {
                window.open(props.quote.shareableViewInBrowserLink, '_blank');
            },
        },
        {
            label: 'Copy link',
            icon: <HtmlLinkIcon size={15} wrapWithDiv={false} />,
            handleSelect: () => {
                navigator.clipboard.writeText(props.quote.shareableViewInBrowserLink);
            },
        },
        {
            label: 'Download a PDF',
            icon: <DownloadIcon size={15} wrapWithDiv={false} />,
            handleSelect: () => {
                if (props.quote.slug) {
                    props.handleDownload(props.quote.slug);
                }
            },
        },
        {
            label: 'Make a copy',
            icon: <CopyIcon size={15} wrapWithDiv={false} />,
            handleSelect: () => {
                props.handleDuplicateQuote(props.quote.id);
            },
        },
        {
            label: 'Revoke',
            icon: <MinusCircleIcon size={15} wrapWithDiv={false} />,
            handleSelect: () => {
                props.handleUpdateStatus(props.quote.id, 'REVOKED');
            },
            isDisabled: props.quote.displayStatus !== 'PUBLISHED',
        },
    ];

    return (
        <>
            <div styleName='quote-row' onClick={() => props.onOpenEditModal(props.quote.id)}>
                <div
                    styleName='quote-row-content'
                    onClick={() => props.onOpenEditModal(props.quote.id)}
                >
                    <TwoLine
                        topRow={`Quote ${props.quote.displayNumber}`}
                        bottomRow={`${props.quote.total.formatted}`}
                        isBoldTopRow={true}
                    />
                    <div className='flex align-start gap-4 flex-dir-col'>
                        {props.quote.sentTime && (
                            <div className='flex align-center justify-center gap-4'>
                                <PaperPlane size={10} wrapWithDiv={false} />
                                Sent
                            </div>
                        )}
                    </div>
                    <StatusBadge
                        level={getStatusLevel(props.quote.displayStatus)}
                        text={getDisplayStatus(props.quote.displayStatus)}
                    />
                </div>

                <Stack isHorizontal={true} spacing={0} alignCenter={true}>
                    <ActionMenu size='small' hasHoverBackground={true} options={options} />
                </Stack>
            </div>
            <div ref={scriptRef} id='nutshell-quotes-container' />
        </>
    );
}
