/* @flow */

import * as React from 'react';

import type {GetQuotes_quotes as Quote, QuoteDisplayStatus} from 'nutshell-graphql-types';

import {Stack} from '../../layout';

import {QuoteRow} from './quote-row';

type Props = {|
    quotes: Quote[],
    onOpenEditModal: (quoteId: string) => void,
    handleDownload: (string) => void,
    handleUpdateStatus: (id: string, status: QuoteDisplayStatus) => void,
    handleDuplicateQuote: (id: string) => void,
|};

export function QuotesSection(props: Props) {
    return (
        <Stack spacing={16}>
            {props.quotes.map((quote) => (
                <QuoteRow
                    key={quote.id}
                    quote={quote}
                    onOpenEditModal={props.onOpenEditModal}
                    handleDownload={props.handleDownload}
                    handleUpdateStatus={props.handleUpdateStatus}
                    handleDuplicateQuote={props.handleDuplicateQuote}
                />
            ))}
        </Stack>
    );
}
