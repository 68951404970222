/* @flow */

import * as React from 'react';
import classNames from 'classnames';

import {PageWrapperContext} from '../layout';

import {EditableLabel} from '../editable-label';
import {ButtonGroup} from '../button-group';
import {PageTitleBarTitle} from './page-title-bar-title';
import type {Breadcrumb} from './page-title-breadcrumbs';

import './page-title-bar.css';

export type Props = {|
    title: string,
    subheaderPrimaryText: string | React.Node,
    icon: React.ComponentType<*>,
    buttons?: React.Node,
    titleBadge?: React.Node,
    isModified?: boolean,
    breadcrumbsTitle?: string,
    breadcrumbs?: Breadcrumb[],
    noMaxWidth?: boolean,
    subheaderSecondaryText?: string,
    // Pass these props if the title can be renamed in place
    editInPlaceProps?: {
        onChange: (newValue: ?string) => Promise<*> | void,
        placeholder: string,
    },
    glossaryButton?: React.Node,
|};

export function PageTitleBar(props: Props) {
    const [titleValue, setTitleValue] = React.useState(props.title);

    React.useEffect(() => {
        setTitleValue(props.title);
    }, [props.title]);

    const {editInPlaceProps} = props;
    const getTitleComponent = () => {
        if (editInPlaceProps) {
            return (
                <EditableLabel
                    value={titleValue}
                    onSaveNewValue={(newValue) => {
                        setTitleValue(newValue);

                        // $FlowIgnore This won't be null
                        return editInPlaceProps.onChange(newValue);
                    }}
                    placeholder={editInPlaceProps.placeholder}
                    shouldAllowUndo={false}
                    allowSavingEmpty={false}
                />
            );
        }
    };

    const styleName = classNames({
        'page-title-bar': true,
        'page-title-bar-with-navbar': NutClientConfig.hasUpdatedNavigation,
    });

    return (
        <PageWrapperContext.Consumer>
            {({flashBanner}) => {
                return (
                    <>
                        <header styleName={styleName}>
                            <PageTitleBarTitle
                                title={titleValue}
                                titleComponent={getTitleComponent()}
                                titleBadge={props.titleBadge}
                                breadcrumbs={props.breadcrumbs}
                                breadcrumbsTitle={props.breadcrumbsTitle}
                                subheaderPrimaryText={props.subheaderPrimaryText}
                                isModified={props.isModified}
                                subheaderSecondaryText={props.subheaderSecondaryText}
                                icon={props.icon}
                                noMaxWidth={props.noMaxWidth}
                                isEditable={Boolean(props.editInPlaceProps)}
                                glossaryButton={props.glossaryButton}
                            />
                            {props.buttons ? <ButtonGroup>{props.buttons}</ButtonGroup> : null}
                        </header>
                        {flashBanner ? (
                            <div styleName='app-flash-banner'>{flashBanner}</div>
                        ) : undefined}
                    </>
                );
            }}
        </PageWrapperContext.Consumer>
    );
}
