/* @flow */

import type {QuoteDisplayStatus} from 'nutshell-graphql-types';
import type {Level} from 'shells/status-badge';

export const getStatusLevel = (status: QuoteDisplayStatus): Level => {
    switch (status) {
        case 'DRAFT':
            return 'info--grey';
        case 'SENT':
            return 'success';
        case 'ACCEPTED':
            return 'success';
        case 'ARCHIVED':
            return 'info--grey';
        case 'PUBLISHED':
            return 'success';
        case 'REVOKED':
            return 'alert';
        case 'VIEWED':
            return 'success';
        default:
            return 'hidden';
    }
};

export const getDisplayStatus = (quote: QuoteDisplayStatus): string => {
    switch (quote) {
        case 'DRAFT':
            return 'Draft';
        case 'SENT':
            return 'Sent';
        case 'ACCEPTED':
            return 'Accepted';
        case 'ARCHIVED':
            return 'Archived';
        case 'PUBLISHED':
            return 'Published';
        case 'REVOKED':
            return 'Revoked';
        case 'VIEWED': // TODO: This is a temporary fix since Viewed is not supported in the backend yet
            return 'Sent';
        default:
            return quote;
    }
};
